
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import AuctionsFilter from '@/components/dropdown/AuctionsFilter.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'auctions-listing',
    components: {
      AuctionsFilter,
      Datatable,
    },
    async setup() {
      const { t, te, locale } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('auctionsListing'), [
          translate('auctions'),
        ]);
        reinitializeComponents();
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_AUCTIONS_STORE);
      });

      await store.dispatch(Actions.GET_AUCTIONS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('id'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('organiserName'),
          key: 'organiserName',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'eventDate',
          sortable: true,
        },
        {
          name: translate('time'),
          key: 'eventTime',
          sortable: true,
        },
        {
          name: translate('venue'),
          key: 'venue',
          sortable: false,
        },
        {
          name: translate('type'),
          key: 'slug',
          sortable: false,
        },
        {
          name: translate('numberOfProperties'),
          key: 'totalProperties',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'is_active',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusAuction = async (auction) => {
        const deletedMessage = auction.is_active
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_AUCTION, auction.id);
          await store.dispatch(Actions.GET_AUCTIONS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = auction.is_active
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editAuction = (id) => {
        router.push({ name: 'auctions-editing', params: { id } });
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AUCTION_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_AUCTIONS);
        loading.value = false;
      };

      const onTypeChange = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AUCTIONS_FILTER, value);
        await store.dispatch(Actions.UPDATE_AUCTION_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_AUCTIONS);
        loading.value = false;
      };

      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AUCTIONS_FILTER, value);
        await store.dispatch(Actions.GET_AUCTIONS);
        loading.value = false;
      };
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.auctionsList),
        tableHeader,
        toggleStatusAuction,
        translate,
        totalItems: computed(() => store.getters.auctionsListCount),
        pageChanged,
        goTo,
        editAuction,
        loading,
        can,
        locale,
        onTypeChange,
        onReset,
      };
    },
  });
