
  import { computed, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import moment from 'moment';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  import { AuctionsFilterListInfo } from '@/store/modules/Auctions/AuctionsModule';
  export default defineComponent({
    name: 'auctions-filter',
    emit: ['type-selected', 'reset'],
    components: {},
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const selectedItems = ref<AuctionsFilterListInfo>({
        type: '',
        dateFrom: '',
        dateTo: '',
        organiserId: '',
        areaId: '',
        size: undefined,
        startingPrice: undefined,
        salePrice: undefined,
        auctionStatus: '',
        propertyTypeId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        if (selectedItems.value.dateFrom) {
          selectedItems.value.dateFrom = moment(
            selectedItems.value.dateFrom
          ).format('YYYY-MM-DD');
        }
        if (selectedItems.value.dateTo) {
          selectedItems.value.dateTo = moment(
            selectedItems.value.dateTo
          ).format('YYYY-MM-DD');
        }
        emit('type-selected', selectedItems.value);
      };

      const onReset = () => {
        selectedItems.value = {
          type: '',
          dateFrom: '',
          dateTo: '',
          organiserId: '',
          areaId: '',
          propertyTypeId: '',
          auctionStatus: '',
          size: undefined,
          startingPrice: undefined,
          salePrice: undefined,
        };

        emit('reset', selectedItems.value);
      };
      await store.dispatch(Actions.GET_ALL_AUCTION_STATUSES);
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'auction' });
      await store.dispatch(Actions.GET_ALL_AUCTION_ORGANISERS);
      await store.dispatch(Actions.GET_ALL_AUCTION_PROPERTY_TYPES);
      reinitializeComponents();
      return {
        areas: computed(() => store.getters.allListAreas),
        auctionStatuses: computed(() => store.getters.auctionStatusesList),
        organaises: computed(() => store.getters.auctionOrganisesList),
        prpertyTypes: computed(() => store.getters.allAuctionPropertyTypes),
        onSubmit,
        onReset,
        translate,
        selectedItems,
      };
    },
  });
